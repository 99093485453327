import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { RoleClient, Auth } from "./RoleClient";
import styles from "./Todo.module.css";
import { Hanko } from "@teamhanko/hanko-elements";
import { SessionExpiredModal } from "./SessionExpiredModal";
import Version from "./version"

const todoAPI = process.env.REACT_APP_TODO_API!;
const hankoAPI = process.env.REACT_APP_HANKO_API!;
const tokenPayload = process.env.REACT_APP_UUID_KEY!; // '89b1463e-4f38-4750-82c6-59f040457e06';

const ROLE_LIST_OLD=
["adult_hi_risk_search","adult_hi_risk","child_hi_risk_search","child_hi_risk","child_diabetes_search",
"child_diabetes","child_psych_search","child_psych","adult_diabetes_search","adult_diabetes","adult_psych_search",
"adult_psych","depression_search","depression","diabetes_search","diabetes","duals_under_65_search",
"duals_under_65","duals_65plus_search","duals_65plus","hh_farmington_search","hh_farmington","hh_clovis_search",
"hh_clovis","jcm_search","jcm","attestation","global_name_search","coe_001_search","coe_001","coe_002_search",
"coe_002","coe_003_search","coe_003","coe_081_search","coe_081","coe_083_search","coe_083","coe_084_search",
"coe_084","coe_091_search","coe_091","coe_092_search","coe_092","coe_093_search","coe_093","coe_094_search",
"coe_094","coe_100_search","coe_100","coe_200_search","coe_200","coe_095_search","coe_095","coe_096_search",
"coe_096","coe_cisc_search","coe_cisc","BLUECROSSBLUESHIELDOFNM_hh_clovis_search",
"BLUECROSSBLUESHIELDOFNM_hh_clovis","BLUECROSSBLUESHIELDOFNM_hh_farmington_search",
"BLUECROSSBLUESHIELDOFNM_hh_farmington","PRESBYTERIANHEALTHPLAN_hh_clovis_search",
"PRESBYTERIANHEALTHPLAN_hh_clovis","PRESBYTERIANHEALTHPLAN_hh_farmington_search",
"PRESBYTERIANHEALTHPLAN_hh_farmington","nm_hh_searchable_search","nm_hh_searchable",
"PRESBYTERIANHEALTHPLAN_search","PRESBYTERIANHEALTHPLAN","PRESBYTERIANHEALTHPLAN_depresssion_search",
"PRESBYTERIANHEALTHPLAN_depresssion",
"BLUECROSSBLUESHIELDOFNM_search","BLUECROSSBLUESHIELDOFNM","BLUECROSSBLUESHIELDOFNM_depresssion_search",
"BLUECROSSBLUESHIELDOFNM_depresssion",
"MOLINAHEALTHCAREOFNEWMEXIC_search",
"MOLINAHEALTHCAREOFNEWMEXIC",
"MOLINAHEALTHCAREOFNEWMEXIC_depresssion_search",
"MOLINAHEALTHCAREOFNEWMEXIC_depresssion",
"MOLINAHEALTHCAREOFNEWMEXIC_hh_clovis_search",
"MOLINAHEALTHCAREOFNEWMEXIC_hh_clovis",
"MOLINAHEALTHCAREOFNEWMEXIC_hh_farmington_search",
"MOLINAHEALTHCAREOFNEWMEXIC_hh_farmington",


"UNITEDHEALTHCARECOMMUNITYPL_search",
"UNITEDHEALTHCARECOMMUNITYPL",
"UNITEDHEALTHCARECOMMUNITYPL_depresssion_search",
"UNITEDHEALTHCARECOMMUNITYPL_depresssion",
"UNITEDHEALTHCARECOMMUNITYPL_hh_clovis_search",
"UNITEDHEALTHCARECOMMUNITYPL_hh_clovis",
"UNITEDHEALTHCARECOMMUNITYPL_hh_farmington_search",
"UNITEDHEALTHCARECOMMUNITYPL_hh_farmington",

"WESTERNSKYCOMMUNITYCARE_search","WESTERNSKYCOMMUNITYCARE","WESTERNSKYCOMMUNITYCARE_depresssion_search",
"WESTERNSKYCOMMUNITYCARE_hh_clovis_search",
"WESTERNSKYCOMMUNITYCARE_hh_clovis","WESTERNSKYCOMMUNITYCARE_hh_farmington_search",
"WESTERNSKYCOMMUNITYCARE_hh_farmington",
"BLUECROSSBLUESHIELDOFNM_096","BLUECROSSBLUESHIELDOFNM_cisc"]
// const
// ROLE_LIST=

// ["adult_hi_risk_search","adult_hi_risk","child_hi_risk_search","child_hi_risk","child_diabetes_search",
// "child_diabetes","child_psych_search","child_psych","adult_diabetes_search","adult_diabetes","adult_psych_search",
// "adult_psych","depression_search","depression","diabetes_search","diabetes","duals_under_65_search",
// "duals_under_65","duals_65plus_search","duals_65plus","hh_farmington_search","hh_farmington","hh_clovis_search",
// "hh_clovis","jcm_search","jcm","attestation","global_name_search","coe_001_search","coe_001","coe_002_search",
// "coe_002","coe_003_search","coe_003","coe_081_search","coe_081","coe_083_search","coe_083","coe_084_search",
// "coe_084","coe_091_search","coe_091","coe_092_search","coe_092","coe_093_search","coe_093","coe_094_search",
// "coe_094","coe_100_search","coe_100","coe_200_search","coe_200","coe_095_search","coe_095","coe_096_search",
// "coe_096","coe_cisc_search","coe_cisc","BLUECROSSBLUESHIELDOFNM_hh_clovis_search",
// "BLUECROSSBLUESHIELDOFNM_hh_clovis","BLUECROSSBLUESHIELDOFNM_hh_farmington_search",
// "BLUECROSSBLUESHIELDOFNM_hh_farmington","PRESBYTERIANHEALTHPLAN_hh_clovis_search",
// "PRESBYTERIANHEALTHPLAN_hh_clovis","PRESBYTERIANHEALTHPLAN_hh_farmington_search",
// "PRESBYTERIANHEALTHPLAN_hh_farmington","WESTERNSKYCOMMUNITYCARE_hh_clovis_search",
// "PRESBYTERIANHEALTHPLAN_search","PRESBYTERIANHEALTHPLAN","PRESBYTERIANHEALTHPLAN_depresssion_search",
// "PRESBYTERIANHEALTHPLAN_depresssion",

// "WESTERNSKYCOMMUNITYCARE_hh_clovis","WESTERNSKYCOMMUNITYCARE_hh_farmington_search",
// "WESTERNSKYCOMMUNITYCARE_hh_farmington","nm_hh_searchable_search","nm_hh_searchable",
// "WESTERNSKYCOMMUNITYCARE_search","WESTERNSKYCOMMUNITYCARE","WESTERNSKYCOMMUNITYCARE_depresssion_search",
// "BLUECROSSBLUESHIELDOFNM_search","BLUECROSSBLUESHIELDOFNM","BLUECROSSBLUESHIELDOFNM_depresssion_search",
// "BLUECROSSBLUESHIELDOFNM_depresssion",
// "MOLINAHEALTHCAREOFNEWMEXIC_search",
// "MOLINAHEALTHCAREOFNEWMEXIC",
// "MOLINAHEALTHCAREOFNEWMEXIC_depresssion_search",
// "MOLINAHEALTHCAREOFNEWMEXIC_depresssion",
// "MOLINAHEALTHCAREOFNEWMEXIC_hh_clovis_search",
// "MOLINAHEALTHCAREOFNEWMEXIC_hh_clovis",
// "MOLINAHEALTHCAREOFNEWMEXIC_hh_farmington_search",
// "MOLINAHEALTHCAREOFNEWMEXIC_hh_farmington",
// "UNITEDHEALTHCARECOMMUNITYPL_search",
// "UNITEDHEALTHCARECOMMUNITYPL",
// "UNITEDHEALTHCARECOMMUNITYPL_depresssion_search",
// "UNITEDHEALTHCARECOMMUNITYPL_depresssion",
// "UNITEDHEALTHCARECOMMUNITYPL_hh_clovis_search",
// "UNITEDHEALTHCARECOMMUNITYPL_hh_clovis",
// "UNITEDHEALTHCARECOMMUNITYPL_hh_farmington_search",
// "UNITEDHEALTHCARECOMMUNITYPL_hh_farmington",

// "BLUECROSSBLUESHIELDOFNM_096","BLUECROSSBLUESHIELDOFNM_cisc"]

const ROLE_LIST=
[
"BLUECROSSBLUESHIELDOFNM",
"MOLINAHEALTHCAREOFNEWMEXIC",
"PRESBYTERIANHEALTHPLAN",
"UNITEDHEALTHCARECOMMUNITYPL"
]


function Role() {
  const navigate = useNavigate();
  const hankoClient = useMemo(() => new Hanko(hankoAPI), []);
  const [roles, setRoles] = useState<string[]>([]);
  const [disabled, setDisabled]=useState(false)
  const [attested, setAttested]=useState(false)
  const [globalNameSearch, setGlobalNameSearch]=useState(false)
  const [user, setUser] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const roleClient = useMemo(() => new RoleClient(todoAPI), []);
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const [checkedState, setCheckedState] = useState(
    new Array(10).fill(true)
  );
  const [finalRoles, setFinalRoles] = useState<string[]>([]);
  const [validUserEmail, setValidUserEmail]=useState("")
  const [filter, setFilter] = useState("ALL")
  const [priorRoles, setPriorRoles] = useState<string[]>([]);
  const [fromDB, setFromDB] = useState(false)
  const [changed, setChanged]= useState(false)
  const [adjudicator, setAdjudicator] = useState("")
  const [accessToken, setAccessToken]=useState("")
  

  const validEmail =(user: string) =>{
    const reg=/^[-a-z0-9_+\.]+\@([-a-z0-9]+\.)+[a-z0-9]{2,4}$/
    return reg.test(user)
  }
  const redirectToLogin = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const redirectToProfile = () => {
    navigate("/profile");
  };
  const redirectToTodo = () => {
    navigate("/todo");
  };
  var initAuth: Auth = {
    user: '',
    disabled: false,
    roles: ['attested', 'risk_high']
   };
   const  trustees = ["chad.zhu@gmail.com", 
                      "chad.zhu@spectruminformatics.com",
                      "beverly.court@spectruminformatics.com",
                      "david.mancuso@spectruminformatics.com",
                    ];

  const RetriveRoles = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("calling retriveRoles()")
    const admin = await hankoClient.user.getCurrent(); //need to make async
    setAdjudicator(admin.email);
    // console.info(`id: ${admin.id}, email: ${admin.email}`);
    // const auth = { name, checked: false, user: user.email };
    // setUser("milestone@outlook.com")
    if (!trustees.includes(admin.email)) {
      setError(new Error(admin.email +" is not authorized"))
      return;
    }
    initAuth.user=admin.email;
    if (!validEmail(validUserEmail)) {
      setError(new Error("need a valid email to proceed!"))
      return;
    }
    setError(new Error("Loading.....")) 
    roleClient
    .fetchRoles(user, accessToken,admin.email)
    .then(async (res) => {
      // console.log("RetrieveRoles() returned from getRoles for " +user);
      if (res.status === 401) {
        modalRef.current?.showModal();
        return;
      }
      if (res.status === 402 ) {
        const admin = await hankoClient.user.getCurrent();
        setError(new Error(admin + " is not authorized"));
        return undefined;
      }

      if (res.status === 403 ) {
        const admin = await hankoClient.user.getCurrent();
        setError(new Error("403 Forbidden, please login with an authorized account"));
        return undefined;
      }

      if (res.status === 403) {
        setError(new Error("403 Forbidden,"))
        setGlobalNameSearch(false);
        setAttested(false);
        setDisabled(false)
        const sorted=ROLE_LIST.sort()
        setRoles([]);
        setFinalRoles([])
        setPriorRoles([])
        // setCheckedState(new Array(roles.length).fill(true));
        setCheckedState(InitRoleStatus(sorted,[]))
        setFilter("ALL")
        setChanged(false)
        setFromDB(true)
        return;
      }

      if (res.status === 404) {
        setError(new Error("user not found, grant permissions and create new user"))
        setGlobalNameSearch(false);
        setAttested(false);
        setDisabled(false)
        // const sorted=ROLE_LIST.sort()
        // setRoles([]);
        const sorted=ROLE_LIST.sort()
        setRoles(sorted);  
        setFinalRoles([])
        setPriorRoles([])
        // setCheckedState(new Array(roles.length).fill(true));
        setCheckedState(InitRoleStatus(sorted,[]))
        setFilter("ALL")
        setChanged(false)
        setFromDB(true)
        return;
      }
      setError(null)
      return res.json();
    })
    .then((auth) => {
      // console.log("RetrieveRoles() CALLED>>>>>>> ")
      if (auth===undefined) {
       return
      }
        const pri= auth as Auth;
        const current_roles = pri.roles;
        const at = pri.attested as boolean
        setAttested(at);
        const dis = pri.disabled as boolean
        setDisabled(dis);
        const new_user = pri.new_user as boolean
        if (new_user) {
          setError(new Error("NEW USER!"));
        }
        const name = pri.global_name_search as boolean
        setGlobalNameSearch(name);
        const sorted=ROLE_LIST.sort()
        setRoles(sorted);  
        setFinalRoles(current_roles)
        setPriorRoles(current_roles)
        // setCheckedState(new Array(roles.length).fill(true));
        setCheckedState(InitRoleStatus(sorted,current_roles))
        setFilter("ALL")
        setChanged(false)
        setFromDB(true)
        // console.log("super user status:")
        // console.log( pri.super_user)
    })
    .catch(setError);
    
  };
  const InitRoleStatus = (allRoles: string[],currentRoles:string[]) => {
    var checkedArray = new Array(allRoles.length).fill(false)
    const newA= checkedArray.map((ck,idx) => { return currentRoles.includes(allRoles[idx]) ? true : false})
    // console.log(newA)
    return newA
  }

  const getToken = useCallback(async () => {
    const admin = await hankoClient.user.getCurrent(); //need to make async
    console.log("getToken for email: " + admin.email);
    roleClient
      .getToken(admin.email,tokenPayload)
      .then((res) => {
        // console.log("getToken() returned from getRoles for " +user);
        if (res.status === 401) {
          modalRef.current?.showModal();
          return;
        }
        return res.json();
      })
      .then((signed) => {
        // console.log("payload returned: " + signed)
        const token=signed.token;
        // console.log("token:" + token)
        setAccessToken(token);
      })
      .catch(setError);

  }, [roleClient]);

  const logout = () => {
    hankoClient.user.logout().catch(setError);
  };
  const changeUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rc =event.currentTarget.value
    let newrc=rc.toLowerCase().trim()
    newrc = newrc.replace("mailto:",""); //remove mailto: from copying email addr from mail
    setUser(newrc);
    setValidUserEmail(newrc)
    setFromDB(false)
  };
  const handleOnChangeAttested =(event: React.ChangeEvent<HTMLInputElement>) =>{
    setAttested(!attested)
    setChanged(true)
  }


  const handleOnChangeDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event;
    setDisabled(currentTarget.checked);
    setChanged(true)
    // console.log(currentTarget.checked)
  };

  const handleOnChangeNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event;
    setGlobalNameSearch(currentTarget.checked);
    setChanged(true)
  };
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    // setChanged(false)
    // console.log(event.target.value)
    console.log("changed status" + changed)
  };

  const handleCheckAll = () => {
    setCheckedState(new Array(checkedState.length).fill(true));
 
    setFinalRoles(ROLE_LIST) 
    setChanged(true)
  };
  const handleUncheckAll = () => {
    setCheckedState(new Array(checkedState.length).fill(false));
    setFinalRoles([])
    setChanged(true)
  };

  const handleRoleChange = (position:number,role:string)=> {
    const updatedCheckedState = checkedState.map(
      (item, index) => {return index === position ? !item : item});
      setCheckedState(updatedCheckedState);
      var newRoles: string[]=[]
      roles.map((r,idx)=> { if (updatedCheckedState[idx]) {
        newRoles.push(r)
      }})
      setFinalRoles(newRoles) 
      setChanged(true)
  };
  const handleSaveChanges = () => {
    if (!validEmail(user)) {
      setError(new Error("need a valid email to proceed!"))
      return;
    }
    if (!fromDB) {
      setError(new Error("need to check first to get the latest from SERVER DB"));
      console.log("skipping save because fromDB is false")
      return;
    }

    if (!changed) {
      setError(new Error("need to check first to get the latest from SERVER DB"));
      console.log("skipping save because changed is false")
      return;
    }

    setValidUserEmail(user)
    setError(null)
    if (disabled) setError(new Error("DISABLED, so skip all other changes"))
 
    const newAuth: Auth ={
      user: user,
      attested: attested,
      disabled: disabled,
      global_name_search: globalNameSearch,
      roles: finalRoles,
      adjudicator: adjudicator,
      token: accessToken
    }
    // console.log(newAuth);
    roleClient
      .addRole(newAuth)
      .then(async (res) => {

        if (res.status === 403) {
          const admin = await hankoClient.user.getCurrent();
          setError(new Error(admin.email + " is not authorized"));
          return;
        }
        if (res.status === 402 ) {
          setError(new Error("payment required"));
          return;
        }
        if (res.status === 404) {
          setError(new Error("Invalid format !!"));
          return;
        }
        if (res.status===201) {
          console.log("Roles added successfully")
          setError(new Error("User permissions updated successfully."))
          setChanged(false)
        }
        // console.log("then() after addRole...")
        // console.log(res.status)
      })
      .catch(setError);
  }
  useEffect(() => {
    if (hankoClient.session.isValid()) {
      getToken();
    } else {
      redirectToLogin();
    }
  }, [hankoClient, getToken, redirectToLogin]);

  useEffect(
    () => hankoClient.onUserLoggedOut(() => redirectToLogin()),
    [hankoClient, redirectToLogin]
  );

  useEffect(
    () => hankoClient.onSessionExpired(() => modalRef.current?.showModal()),
    [hankoClient]
  );

  useEffect(
    () => { 
      // console.log("validUserEmail: "+validUserEmail)
      if (validUserEmail.length==0) {
        setError(null);
        return;
      }
      if (validEmail(validUserEmail)){
        // console.log("useEffect fired for change of name "+ validUserEmail)
        setError(null)
      } else {
        setError(new Error("not a valid email"));
      }
    }
    ,[validUserEmail]
  );

  useEffect(
    () => { 
      // console.log("group filet: " +filter)
      // console.log("changed status: " +changed)
      if (user.length===0) return;
      const sorted=ROLE_LIST
      if (filter==="ALL") {
        const sorted=ROLE_LIST
        setRoles(sorted)
        setCheckedState(InitRoleStatus(sorted, priorRoles))
        setFinalRoles(priorRoles)
        return
      }
    
      const newRoles=sorted.filter((r)=> r.includes(filter));
      // console.log(newRoles)
      setCheckedState(InitRoleStatus(newRoles, priorRoles))
      //recalculate finalRoles based on what in checkState[]
      var newFinalRoles: string[]=[]
      newRoles.map((r,idx)=> { if (checkedState[idx]) {
        return newFinalRoles.push(r)
      }})
      setFinalRoles(newFinalRoles)
      setRoles(newRoles)
    
    }
    ,[filter]
  )
  return (
    <>
      <SessionExpiredModal ref={modalRef} />
      <nav className={styles.nav}>
        <button onClick={logout} className={styles.button}>
          Logout
        </button>
        <button onClick={redirectToProfile} className={styles.button}>
          Profile
        </button>
        <button disabled className={styles.button}>
        Permissions
        </button>
        <button onClick={redirectToTodo} className={styles.button}>
          IP Address
        </button>
      </nav>
      <div className={styles.content}>
        <h1 className={styles.headline}>Permissions</h1>
        <Version />
        <div className={styles.error}>{error?.message}</div>
        <form onSubmit={RetriveRoles} className={styles.form}>
        <button type={"submit"} className={styles.button}>
            Verify
          </button>
          <input 
            required
            className={styles.input}
            type={"text"}
            value={user}
            onChange={changeUserChange}
            placeholder="Enter a valid email to start, verify before making changes "
          />
          
        </form>
    
        <div>
        <button onClick={handleSaveChanges} disabled={!fromDB || !changed} className={styles.button}>
            Save
        </button>
        <button onClick={handleCheckAll} disabled={!(filter==='ALL')} className={styles.button}>
            Check All
        </button>
        <button onClick={handleUncheckAll} disabled={!(filter==='ALL')} className={styles.button}>
            Uncheck All
        </button>

        <label>
          <span>Group by  </span>
          <select value={filter} onChange={(event) => {setFilter(event.target.value)}}>
            <option value="ALL">All</option>
            <option value="BLUECROSSBLUESHIELDOFNM">BLUE CROSS</option>
            <option value="MOLINAHEALTHCAREOFNEWMEXIC">Molina</option>
            <option value="UNITEDHEALTHCARECOMMUNITYPL">UHC</option>
            <option value="PRESBYTERIANHEALTHPLAN"> PRESBYTERIAN </option>
            <option value="coe"> CoE </option>
          </select>
        </label>
        <hr></hr>
        </div>
        <div className={styles.list}>     
            <div className={styles.item}>
              <input
                className={styles.checkbox}
                id='checkbox-attested'
                type={"checkbox"}
                value='{attested}'
                checked={attested}
                onChange={handleOnChangeAttested}
              />
              <label className={styles.description} htmlFor='checkbox-attested'>
                Attestation
              </label>
            </div>
            <div className={styles.item} key={1001}>
              <input
                className={styles.checkbox}
                id='checkbox-disabled'
                type={"checkbox"}
                value='disabled'
                checked={disabled}
                onChange={handleOnChangeDisabled}
              />
              <label className={styles.description} htmlFor='checkbox-disabled'>
                Disabled
              </label>
          </div>
            <div className={styles.item}>
              <input
                className={styles.checkbox}
                id='checkbox-name-search'
                type={"checkbox"}
                value='{namesearch}'
                checked={globalNameSearch}
                onChange={handleOnChangeNameSearch}
              />
              <label className={styles.description} htmlFor='checkbox-name-search'>
                Global Name Search
              </label>
            </div>

          {roles && roles.map((role, index) => (
            <div className={styles.item} key={index}>
              <input
                className={styles.checkbox}
                id={`checkbox-${index}`}
                type={"checkbox"}
                value={role}
                checked={checkedState[index]}
                onChange={() => handleRoleChange(index,role)}
              />
              <label className={styles.description} htmlFor={`checkbox-${index}`}>
                {role}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Role;
